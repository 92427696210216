import React from "react"
import Layout from "../components/layout/layout"
import InnerWrapper from "../components/layout/innerWrapper"
import { Row, Col } from "react-bootstrap"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import { Link, graphql } from "gatsby"
import AboutSidebar from "../components/layout/about-sidebar"
import SEO from "../components/seo"

const LocationDetail = ({ data }) => {
  const { location } = data
  return (
    <Layout>
      <SEO title={location.seoTitle} description={location.seoDescription} />
      <InnerWrapper>
        <div className="page-hero"></div>

        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/about">About</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/about/our-locations">Our Locations</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <div>{location.title}</div>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Row className="generalcontent">
          <Col md={3}>
            <AboutSidebar></AboutSidebar>
          </Col>
          <Col md={9}>
            <Row>
              <h1 className="generalheader">{location.title}</h1>
              <Col md={6}>
                <h4>
                  {location.street}
                  <br />
                  {location.city}, {location.state} {location.zip}
                </h4>
                <br />
                <div className="infocontainer">
                  {location.manager && (
                    <>
                      <div className="left-cell">
                        <h5>
                          <strong>Contact:</strong>
                        </h5>
                      </div>
                      <div className="right-cell">
                        <h5>{location.manager}</h5>
                      </div>{" "}
                    </>
                  )}
                  {location.phone && (
                    <>
                      <div className="left-cell">
                        <h5>
                          <strong>Phone:</strong>
                        </h5>
                      </div>
                      <div className="right-cell">
                        <h5>
                          <a href={`tel:${location.phone}`}>{location.phone}</a>
                        </h5>
                      </div>
                    </>
                  )}
                  <div className="left-cell">
                    <h5>
                      <strong>Hours:</strong>
                    </h5>
                  </div>
                  <div className="right-cell">
                    <h5>{location.hours}</h5>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <img className="locationimg" src={location.imagePaths[0]} />
                <br />
                <iframe
                  id="PageContent_loc_gMapFrame"
                  height="250"
                  frameBorder="0"
                  src={location.googleMapsEmbedLink}
                ></iframe>
              </Col>
            </Row>
            {/* <hr />
            <Row>
              <h4>Select Communities & Surrounding Areas Served:</h4>
            </Row>
            <Row>
              <Col md={4}>
                <ul className="locationlist">
                  <li>Avon, Connecticut</li>
                  <li>Bloomfield, Connecticut</li>
                  <li>Bristol, Connecticut</li>
                  <li>Canton, Connecticut</li>
                  <li>Deep River, Connecticut</li>
                  <li>Durham, Connecticut</li>
                  <li>East Haddam, Connecticut</li>
                  <li>Essex, Connecticut</li>
                  <li>Farmington, Connecticut</li>
                  <li>Glastonbury, Connecticut</li>
                </ul>
              </Col>
              <Col md={4}>
                <ul className="locationlist">
                  <li>Granby, Connecticut</li>
                  <li>Harwinton, Connecticut</li>
                  <li>Litchfield, Connecticut</li>
                  <li>Lyme, Connecticut</li>
                  <li>Manchester, Connecticut</li>
                  <li>Marlborough, Connecticut</li>
                  <li>New Hartford, Connecticut</li>
                  <li>Old Lyme, Connecticut</li>
                  <li>Old Saybrook, Connecticut</li>
                  <li>Plymouth, Connecticut</li>
                </ul>
              </Col>
              <Col md={4}>
                <ul className="locationlist">
                  <li>Portland, Connecticut</li>
                  <li>Rocky Hill, Connecticut</li>
                  <li>Simsbury, Connecticut</li>
                  <li>South Windsor, Connecticut</li>
                  <li>Terryville, Connecticut</li>
                  <li>Thomaston, Connecticut</li>
                  <li>Torrington, Connecticut</li>
                  <li>West Hartford, Connecticut</li>
                  <li>Westbrook, Connecticut</li>
                  <li>Windsor, Connecticut</li>
                </ul>
              </Col>
            </Row>*/}
            <hr />
            <Row>
              <p>
                From curbside to backyard, Walpole Outdoors has the fencing,
                pergolas, automatic driveway gates, arbors and other outdoor
                structures – such as cabanas, gazebos, and pavilions – to
                transform the area around your home into all that you would ever
                want it to be.
              </p>

              <p>
                At our {location.city}, {location.state} store, you’ll see
                exceptional styles in handcrafted fences. Walpole has more than
                40 standard fence styles to choose from, including picket
                fencing, lattice fencing, privacy board fencing, and – working
                from your drawings, specifications, or photographs – we’ll
                custom craft fencing to complement your specific needs and your
                home’s architecture.
              </p>

              <p>
                Walpole Outdoors pergolas are the standard all others are
                measured by, for style and handcrafted excellence in
                construction.
              </p>

              <p>
                You may be considering an attached pergola for your patio,
                porch, or entranceway, or a freestanding pergola for an area by
                the pool or in your yard. Whatever your requirements, your
                Walpole pergola can be the outdoor room of your dreams – with an
                outdoor kitchen, furniture, and a manual or automatic Walpole
                pergola canopy on top.
              </p>

              <p>
                While viewing the indoor and outdoor displays at your{" "}
                {location.city}, {location.state} Walpole Outdoors, don’t miss
                our charming arbors, some with seats, and others with swings.
                For curb appeal, view our mail posts with mail boxes, and
                lantern posts with lanterns, planters, and more.
              </p>

              <p>
                Walpole has been the leader in handcrafted cedar fence for more
                than 80 years. Today, we also offer most of our products crafted
                in AZEK. This low maintenance vinyl material looks exactly like
                natural wood, yet won’t rot, and will hold its pristine looks
                for years.
              </p>

              <p>
                We invite you to visit us soon and set up a free, at-home fence{" "}
                <a href="/consultation">design consultation</a> with a Walpole
                expert – who will take measurements around your property and
                assist you with style options. Stop by, or call{" "}
                <a href={`tel:${location.phone}`}>{location.phone}</a>.
              </p>
            </Row>
          </Col>
        </Row>
      </InnerWrapper>
    </Layout>
  )
}

export default LocationDetail

export const query = graphql`
  query($id: String!) {
    location(id: { eq: $id }) {
      city
      state
      imagePaths
      street
      title
      zip
      hours
      googleMapsLink
      googleMapsEmbedLink
      phone
      manager
      seoDescription
      seoTitle
    }
  }
`
